import Vanica1 from '../../../Media/Summarecon Bekasi/Vanica/Vanica (1).webp'
import Vanica2 from '../../../Media/Summarecon Bekasi/Vanica/Vanica (2).webp'
import Vanica3 from '../../../Media/Summarecon Bekasi/Vanica/Vanica (3).webp'
import Vanica4 from '../../../Media/Summarecon Bekasi/Vanica/Vanica (4).webp'
import Vanica5 from '../../../Media/Summarecon Bekasi/Vanica/Vanica (5).webp'
import Vanica6 from '../../../Media/Summarecon Bekasi/Vanica/Vanica (6).webp'
import Vanica7 from '../../../Media/Summarecon Bekasi/Vanica/Vanica (7).webp'
import Vanica8 from '../../../Media/Summarecon Bekasi/Vanica/Vanica (8).webp'
import Vanica9 from '../../../Media/Summarecon Bekasi/Vanica/Vanica (9).webp'
import Vanica10 from '../../../Media/Summarecon Bekasi/Vanica/Vanica (10).webp'



const Vanicaimages =[
Vanica1,Vanica2,Vanica3,Vanica4,Vanica5,Vanica6,Vanica7,Vanica8,Vanica9,Vanica10
]

export default Vanicaimages;