import Jasmia1 from '../../../Media/Summarecon Bekasi/Jasmia/Jasmia (1).webp'
import Jasmia2 from '../../../Media/Summarecon Bekasi/Jasmia/Jasmia (2).webp'
import Jasmia3 from '../../../Media/Summarecon Bekasi/Jasmia/Jasmia (3).webp'
import Jasmia4 from '../../../Media/Summarecon Bekasi/Jasmia/Jasmia (4).webp'
import Jasmia5 from '../../../Media/Summarecon Bekasi/Jasmia/Jasmia (5).webp'
import Jasmia6 from '../../../Media/Summarecon Bekasi/Jasmia/Jasmia (6).webp'
import Jasmia7 from '../../../Media/Summarecon Bekasi/Jasmia/Jasmia (7).webp'
import Jasmia8 from '../../../Media/Summarecon Bekasi/Jasmia/Jasmia (8).webp'
import Jasmia9 from '../../../Media/Summarecon Bekasi/Jasmia/Jasmia (9).webp'
import Jasmia10 from '../../../Media/Summarecon Bekasi/Jasmia/Jasmia (10).webp'
import Jasmia11 from '../../../Media/Summarecon Bekasi/Jasmia/Jasmia (11).webp'
import Jasmia12 from '../../../Media/Summarecon Bekasi/Jasmia/Jasmia (12).webp'
import Jasmia13 from '../../../Media/Summarecon Bekasi/Jasmia/Jasmia (13).webp'
import Jasmia14 from '../../../Media/Summarecon Bekasi/Jasmia/Jasmia (14).webp'
import Jasmia15 from '../../../Media/Summarecon Bekasi/Jasmia/Jasmia (15).webp'
import Jasmia16 from '../../../Media/Summarecon Bekasi/Jasmia/Jasmia (16).webp'



const Jasmiaimages =[
Jasmia1,Jasmia2,Jasmia3,Jasmia4,Jasmia5,Jasmia6,Jasmia7,Jasmia8,Jasmia9,Jasmia10,Jasmia11,Jasmia12,Jasmia13,Jasmia14,Jasmia15,Jasmia16
]

export default Jasmiaimages;