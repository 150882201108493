import Viola1 from '../../../Media/Summarecon Bekasi/Viola/Viola (1).webp'
import Viola2 from '../../../Media/Summarecon Bekasi/Viola/Viola (2).webp'
import Viola3 from '../../../Media/Summarecon Bekasi/Viola/Viola (3).webp'
import Viola4 from '../../../Media/Summarecon Bekasi/Viola/Viola (4).webp'
import Viola5 from '../../../Media/Summarecon Bekasi/Viola/Viola (5).webp'
import Viola6 from '../../../Media/Summarecon Bekasi/Viola/Viola (6).webp'
import Viola7 from '../../../Media/Summarecon Bekasi/Viola/Viola (7).webp'
import Viola8 from '../../../Media/Summarecon Bekasi/Viola/Viola (8).webp'
import Viola9 from '../../../Media/Summarecon Bekasi/Viola/Viola (9).webp'
import Viola10 from '../../../Media/Summarecon Bekasi/Viola/Viola (10).webp'



const Violaimages =[
Viola1,Viola2,Viola3,Viola4,Viola5,Viola6,Viola7,Viola8,Viola9,Viola10
]

export default Violaimages;